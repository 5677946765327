import * as articleResultsTemplate from './templates/results.html';


class ArticlesController {
    static $inject = ['$location', 'articleSearchService', 'map', '$mdSidenav'];

    articles;
    errorMessage;

    constructor (private $location, private searchService, private map, private $mdSidenav) {

    }

    $onInit () {
        this.map.clearHighlightedFeatures();
        this.map.clearSelectedFeatures();
        this.searchService.setPropertiesFromParams();
        this.search();
        this.$mdSidenav('view').open();
    }

    search () {
        this.searchService.search().then((articles) => {
            articles.$promise.then((articles) => {
                this.articles = articles;
                if (articles.length === 1) {
                    this.$location.url(articles[0].path).replace();
                } else {
                    this.showArticlesOnMap(articles);
                }
            }).catch((response) => {
                if (!response) {
                    return;  // Query was cancelled
                }
                if (response.status === 404) {
                    this.errorMessage = 'No articles were found matching those filters.';
                } else {
                    this.errorMessage = 'Unable to search for articles at this time.'
                }
            });
        });
    }

    previousPage () {
        this.searchService.currentPage = this.searchService.previousPage;
        this.$location.search('page', this.searchService.previousPage);
    }

    nextPage () {
        this.searchService.currentPage = this.searchService.nextPage;
        this.$location.search('page', this.searchService.nextPage);
    }

    /**
     * Highlight the specified articles on the map.
     *
     * Unhighlight all other articles.
     *
     * @param {Article[]} articles
     */
    showArticlesOnMap (articles) {
        const map = this.map;
        const highlightFeature = map.highlightFeature.bind(map);
        const numArticles = articles.length;

        let coordinates, article, points, numArticlePoints, point, i, j;
        coordinates = []

        map.clearHighlightedFeatures();

        // Highlight each article's points
        for (i = 0; i < numArticles; ++i) {
            article = articles[i];
            points = article.points;
            numArticlePoints = points.length;
            for (j = 0; j < numArticlePoints; ++j) {
                point = points[j];
                coordinates.push([point.long, point.lat])
                highlightFeature(point.getFeature());
            }
        }

        map.fitExtentOfCoordinates(coordinates, false, 100000);
    }

    goToArticle (article) {
        this.$location.url(article.path);
    }

    highlightArticle (article) {
        const map = this.map;
        const points = article.points;
        const numPoints = points.length;
        const selectFeature = map.selectFeature.bind(map);
        let point, i;
        for (i = 0; i < numPoints; ++i) {
            point = points[i];
            selectFeature(point.getFeature());
        }
    }

    unhighlight () {
        this.map.clearSelectedFeatures();
    }

    reset() {
        this.searchService.reset();
        this.map.clearHighlightedFeatures();
        this.map.clearSelectedFeatures();
        this.$location.url('/');
    }

    closeViewNav() {
        this.$mdSidenav('view').close();
    }
}


const ArticlesComponent = {
    controller: ArticlesController,
    template: articleResultsTemplate
}


export default ArticlesComponent;
