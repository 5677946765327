import { element } from 'angular';

import util from './util';
import * as appTemplate from './app.html';
import * as aboutTemplate from './templates/about.html';
import * as infoSheetTemplate from './templates/info-sheet.html';
import * as searchResultsSheetTemplate from './articles/templates/search-results-sheet.html';
import * as articleSheetTemplate from './articles/templates/article-sheet.html';

class AppController {
    advancedSearchPanel;
    articles;
    csrfToken;
    hosts;
    user;
    isWelcomeActive;
    isLeftNavActive;
    isFilterActive;
    isDetailPanelActive;
    isKiosk;

    static $inject = [
        '$window', '$scope', '$cookies', '$http', '$location', '$route', '$timeout',
        '$mdBottomSheet', '$mdDialog', '$mdMedia', '$mdSidenav', 'appConfig', 'notifier',
        'articleFetchService', 'articleSearchService', 'map'];

    constructor (private $window, private $scope, private $cookies, private $http,
                 private $location, private $route, private $timeout, private $mdBottomSheet,
                 private $mdDialog, private $mdMedia, private $mdSidenav, private appConfig,
                 private notifier, private fetchService, private searchService, private map) {
        this.advancedSearchPanel = element(document.getElementById('advanced-search-filters'));

        this.csrfToken = $cookies.get('csrftoken');
        this.hosts = appConfig.hosts;
        this.user = appConfig.user;
        this.isKiosk = false; 

        //Menu
        this.isWelcomeActive = false;
        this.isLeftNavActive = false;
        this.isFilterActive = false;
        this.isDetailPanelActive = false;

        /* Event handlers */

        $scope.$on('$routeChangeStart', () => {
            if ($location.path() === '/') {
                this.reset();
                // HACK: When returning to the Wayfinder home page, wait
                // until the view element is gone then update the map's
                // position.
               const updateMapPosition = () => {
                    const view = document.getElementById('view');
                    if (view) {
                        $timeout(updateMapPosition, 50);
                    } else {
                        this.updateMapPosition();
                    }
               };
               updateMapPosition();
               this.isWelcomeActive = true;
            } else {
                fetchService.article = null;
                this.closeAdvancedSearch();
                $mdBottomSheet.cancel();
                this.closeWelcomePanel();
            }
            
        });

        $scope.$on('$routeChangeSuccess', () => {
            if ($window.googleAnalytics) {
                $window.googleAnalytics('send', 'pageview', { page: $location.url() });
            } else {
                console.debug('Google Analytics not enabled; not sending pageview event');
            }

            if ($location.path() === '/') {
                if (document.getElementById('view')) {
                    this.$mdSidenav('view').close();
                    this.isDetailPanelActive = false;
                }
            } else {
                if ( !document.getElementById('view') ) {
                    this.$mdSidenav('view').open();
                    this.isDetailPanelActive = true;
                }
            }
        });

        $scope.$watch(() => {
            return util.getComputedStyle('view', 'display');
        }, () => {
            this.updateMapPosition();
        });

        // When the window is resized, update the map's left position.
        element($window).on('resize', () => {
            this.updateMapPosition();
            if ($mdMedia('gt-sm')) {
                $mdBottomSheet.cancel();
            }
        });

        /* Initialization */

        map.setCenterToInitialCenter();
    }

    $onInit () {
        const activityEl = element(document.getElementById('activity-indicator'));

        this.$scope.$watch(() => {
            return this.$http.pendingRequests.length;
        }, (newLength, oldLength) => {
            if (newLength > 0) {
                activityEl.removeClass('invisible');
            } else {
                this.$timeout(function () {
                    activityEl.addClass('invisible');
                }, 500);
            }
        });

    }

    isBusy () {
        return (
            this.searchService.searchInProgress ||
            this.fetchService.fetchInProgress
        );
    }

    closeViewNav () {
        this.$mdSidenav('view').close();
    }
    openViewNav () {
        this.$mdSidenav('view').open();
    }

    openLeftSidenav () {
        this.$mdSidenav('sidenav-left').open().then(this.fixSidenavLinks.bind(this));
        this.closeAdvancedSearch();
        this.closeWelcomePanel();
        this.isWelcomeActive = false;
        this.isLeftNavActive = true;
        this.isFilterActive = false;
        if (!this.isKiosk) {
            this.isKiosk = !!document.getElementById('isKiosk-wayfinder');
        }
    }

    closeLeftSidenav () {
        this.$mdSidenav('sidenav-left').close();
        this.isLeftNavActive = false;
    }

    toggleLeftSidenav(){
        this.$mdSidenav('sidenav-left').toggle();
        this.closeAdvancedSearch();
        this.closeWelcomePanel();
        this.isLeftNavActive = !this.isLeftNavActive;
        if( this.isLeftNavActive == true ){
            this.isWelcomeActive = false;
            this.isFilterActive = false;
        }
        if (!this.isKiosk) {
            this.isKiosk = !!document.getElementById('isKiosk-wayfinder');
        }
    }

    /* Welcome Panel */
    openWelcomePanel() {
        this.$mdSidenav('welcome').open();
        this.isWelcomeActive = true;
        this.isLeftNavActive = false;
        this.isFilterActive = false;
    }

    closeWelcomePanel() {
        this.$mdSidenav('welcome').close();
        this.isWelcomeActive = false;
    }

    toggleWelcomePanel() {
        this.$mdSidenav('welcome').toggle();
        this.isWelcomeActive = !this.isWelcomeActive;
        if( this.isWelcomeActive == true ){
            this.isFilterActive = false;
            this.isLeftNavActive = false;
        }
    }

    showAbout () {
        const $mdDialog = this.$mdDialog;
        const $mdMedia = this.$mdMedia;
        const appConfig = this.appConfig;
        $mdDialog.show({
            template: aboutTemplate,
            fullscreen: $mdMedia('xs'),
            controllerAs: '$ctrl',
            controller: function () {
                this.hosts = appConfig.hosts;
                this.close = () => {
                    $mdDialog.hide();
                }
            }
        });
    }

    openAdvancedSearch () {
        this.$mdSidenav('advanced-search-filters').open();
        this.closeLeftSidenav();
        this.closeWelcomePanel();
        this.isWelcomeActive = false;
        this.isLeftNavActive = false;
        this.isFilterActive = true;
    }

    closeAdvancedSearch () {
        this.$mdSidenav('advanced-search-filters').close();
        this.isFilterActive = false;
    }

    toggleAdvancedSearch () {
        this.$mdSidenav('advanced-search-filters').toggle();
        this.closeLeftSidenav();
        this.closeWelcomePanel();
        this.isFilterActive = !this.isFilterActive;
        if( this.isFilterActive == true ){
            this.isWelcomeActive = false;
            this.isLeftNavActive = false;
        }
    }

    search () {
        this.searchService.currentPage = 1;
        if (this.searchService.hasFilters()) {
            let filters = this.searchService.getSearchFiltersFromProperties();
            this.$location.path('/search').search(filters);
            this.$route.reload();
        }
    }

    resetSearch () {
        this.reset({zoomToExtent: false});
        this.$location.url('/');
    }

    reset (options: {zoomToExtent: boolean} = {zoomToExtent: true}) {
        const map = this.map;

        this.fetchService.reset();
        this.searchService.reset();

        this.$mdBottomSheet.cancel();
        this.closeAdvancedSearch();

        map.clearHighlightedFeatures();
        map.clearSelectedFeatures();
        if (options.zoomToExtent) {
            map.zoomToFullExtent();
            map.setCenterToInitialCenter();
        }
    }

    /* Home Button */
    showHome(){
        this.closeLeftSidenav();
        this.closeAdvancedSearch();
        this.toggleWelcomePanel();
        this.isWelcomeActive = true;
        this.isLeftNavActive = false;
        this.isFilterActive = false;
    }

    /* Bottom Sheets */

    showInfoSheet () {
        const $mdBottomSheet = this.$mdBottomSheet;
        const map = this.map;

        this.$mdBottomSheet.show({
            template: infoSheetTemplate,
            controller: function () {
                this.map = map;
                this.switchLayer = (layer) => {
                    if (this.map.switchLayer(layer)) {
                        $mdBottomSheet.cancel();
                    }
                }
            },
            controllerAs: '$ctrl'
        });
    }

    showSearchResultsSheet () {
        const appController = this;
        const searchService = this.searchService;

        this.$mdBottomSheet.show({
            template: searchResultsSheetTemplate,
            controller: function () {
                this.articles = searchService.results;
                this.showArticle = (article) => {
                    appController.$location.url(article.path);
                    appController.$mdBottomSheet.cancel();
                }
            },
            controllerAs: '$ctrl'
        });
    }

    showArticleSheet (article) {
        this.$mdBottomSheet.show({
            locals: {
                article: article
            },
            template: articleSheetTemplate,
            controller: function () {},
            controllerAs: '$ctrl',
            bindToController: true
        });
    }

    /* Helpers */

    fixSidenavLinks () {
        // HACK: Clicking links in the sidenav doesn't close the sidenav
        //       by default.
        const links = document.querySelectorAll('md-sidenav a');
        Array.prototype.forEach.call(links, (link) => {
            link.addEventListener('click', this.closeLeftSidenav.bind(this), false);
        })
    }

    // Update left position of #map based on whether #view is
    // displayed and its width. Whenever #view is displayed, #map
    // should be pushed to the right so the #view panel doesn't hide
    // part of the map.
    updateMapPosition () {
        const map = this.map;
        const viewEl = document.getElementById('view');
        const viewElDisplayValue = util.getComputedStyle(viewEl, 'display');
        const viewNotDisplayed = !viewElDisplayValue || viewElDisplayValue === 'none';
        const mapEl = document.getElementById('map');
        const mapCurrentLeft = util.getComputedStyle(mapEl, 'left');
        const mapNewLeft = viewNotDisplayed ? '0px' : util.getComputedStyle(viewEl, 'width');
        if (mapNewLeft != mapCurrentLeft) {
            //mapEl.style.left = mapNewLeft;
            map.updateSize();
        }
    }
}


const AppComponent = {
    controller: AppController,
    template: appTemplate
}


export default AppComponent;
