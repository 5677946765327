const util = {
    /**
     * Return object if defined or default otherwise.
     *
     * This is a convenience for use in functions that take optional
     * arguments.
     *
     * @param {*} obj
     * @param {*} defaultVal
     * @returns {*}
     */
    default: function (obj, defaultVal) {
        if (typeof obj === 'undefined') {
            return defaultVal;
        }
        return obj;
    },

    hasKeys: function (obj) {
        let k;
        for (k in obj) {
            if (obj.hasOwnProperty(k)) {
                return true;
            }
        }
        return false;
    },

    stripLeadingSlashes: function  (string) {
        return string.replace(/^\/+/, '');
    },

    stripTrailingSlashes: function  (string) {
        return string.replace(/\/+$/, '');
    },

    /**
     * Join a variable number of path segments into a single path.
     *
     * If an absolute path segment is encountered, any segments
     * prior to that segment will be discarded. A segment is
     * considered absolute if it starts with either a slash or
     * a scheme.
     *
     * For convenience, all defined, non-null segments will be
     * automatically converted to strings via .toString().
     *
     * @param {...String} segments
     * @returns {String}
     */
    pathJoin: function  (...args) {
        let segments = [];
        let segment, type, i, j;
        for (i = 0, j = arguments.length - 1; i <= j; ++i) {
            segment = args[i];
            type = typeof segment;
            if (type !== 'undefined' && segment !== null) {
                if (type !== 'string') {
                    segment = segment.toString();
                }
            } else {
                throw new Error('util.pathJoin() can\'t handle undefined or null segments');
            }
            // Discard prior segments when an abs. segment found
            if (segment.match(/^\//) || segment.match(/^[a-z]+?:\/\//)) {
                segments = [];
            }
            // Remove trailing slashes except from last segment
            if (i < j) {
                segment = segment.replace(/\/+$/, '');
            }
            segments.push(segment);
        }
        return segments.join('/');
    },

    /**
     * Generate an application-relative URL.
     *
     * @param {...String} segments
     * @returns {String}
     */
    url: function  (...args) {
        const path = util.stripLeadingSlashes(util.pathJoin.apply(util, args));
        return util.pathJoin('/', path);
    },

    serviceUrl: function  (...args) {
        const path = util.stripLeadingSlashes(util.pathJoin.apply(util, args));
        return util.pathJoin(`${process.env.API_HOST}/api`, path);
    },

    /**
     * Easy access to an element's styles or a single style.
     *
     * @param el {String|Element} Element to get styles for.
     * @param name {String=} Name of a single style to get; if this
     * is passed, only the value of named style will be returned.
     * @returns {CSSStyleDeclaration|String|null} All styles or a
     * single style value if `name` is passed. If `el` doesn't exist
     * or is null, null will be returned.
     */
    getComputedStyle: function  (el, name) {
        let style;
        if (typeof el === 'string') {
            el = document.getElementById(el);
        }
        if (!el) {
            return null;
        }
        style = window.getComputedStyle(el, null);
        return (name ? style.getPropertyValue(name) : style);
    },
};

export default util;
