import { element } from 'angular';


// TODO: Make into a component or directive?
// TODO: Allow multiple notifications to be displayed at once?
export default function notifierFactory () {
    const container = element(document.getElementById('notification-container'));
    const titleContainer = element(document.getElementById('notification-title'));
    const bodyContainer = element(document.getElementById('notification-body'));

    let currentClass;

    return {
        error: function (message, title, timeout) {
            this.show('error', message, title || 'Error', timeout);
        },
        info: function (message, title, timeout) {
            this.show('info', message, title || 'Info', timeout);
        },
        success: function (message, title, timeout) {
            this.show('success', message, title || 'Success', timeout);
        },
        warning: function (message, title, timeout) {
            this.show('warning', message, title || 'Warning', timeout);
        },
        show: function (type, message, title, timeout) {
            container.removeClass(currentClass);
            container.addClass(type);
            container.css({display: 'block'});
            titleContainer.html(title);
            bodyContainer.html(message);
            currentClass = type;
        },
        hide: function () {
            container.css({display: 'none'});
        }
    };
}
