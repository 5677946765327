import { isDefined } from 'angular';

import { unByKey } from 'ol/Observable';
import DrawInteraction from 'ol/interaction/Draw';

import * as pointFormTemplate from './templates/form.html';


class PointNewController {
    static $inject = [
        '$scope', '$location', 'appConfig', 'map', 'searchFactory', 'Point', 'Article'];

    point;

    formConfig;
    formErrors;

    articleSearch;
    articleSearchResults;
    articleSearchTitle;

    constructor (private $scope, private $location, private appConfig, private map,
                 private searchFactory, private Point, private Article) {
        const mainSiteName = appConfig.mainSiteName;

        let point = new Point({
            articles: []
        });

        this.point = point;

        this.formConfig = {
            type: 'add',
            submitValue: 'Add Point',
            title: 'Add Point'
        };
        this.formErrors = null;

        this.articleSearch = searchFactory(Article, {
            successCallback: (results) => {
                this.articleSearchResults = results;
            },
            errorCallback: () => {
                this.articleSearchResults = null;
            }
        });

        const relatedArticleId = $location.search().related_article_id;
        if (typeof relatedArticleId !== 'undefined') {
            this.articleSearch.getById(relatedArticleId).$promise.then((article) => {
                this.point.addArticle(article);
            });
        }

        const draw = new DrawInteraction({
            features: map.highlightOverlay.getSource().getFeatures(),
            type: 'Point',
            style: map.styleMap.redDot
        });

        draw.on('drawend', (event) => {
            let feature = event.feature;
            let listenerKey = feature.on('change', (event) => {
                point.setCoordinates(event.target.getGeometry().getCoordinates(), true);
                $scope.$digest();
            });

            map.removeInteraction(draw);
            map.addFeature(mainSiteName, feature);
            map.modifiableFeatures.push(feature);
            map.highlightFeature(feature);

            point.setFeature(feature);
            point.setCoordinates(feature.getGeometry().getCoordinates(), true);

            $scope.$digest();
            $scope.$on('$destroy', () => {
                unByKey(listenerKey);
                map.modifiableFeatures.clear();
                map.reloadFeatureSource(mainSiteName, feature.getGeometry().getExtent(), true);
            });
        });

        map.addInteraction(draw);

        $scope.$on('$destroy', () => {
            map.removeInteraction(draw);
        });
    }

    updateCoordinates () {
        const point = this.point;
        if (isDefined(point.long) && isDefined(point.lat)) {
            point.showOnMap();
        }
    }

    /**
     * Create new point.
     */
    save () {
        const $location = this.$location;
        const action = this.point.id ? '$update' : '$save';
        this.point[action]((point) => {
            $location.path(point.path);
        }, (response) => {
            this.formErrors = response.data;
        });
    }

    cancel () {
        this.$location.path('/points');
    }

    addArticle (article) {
        this.articleSearchTitle = null;
        this.articleSearchResults = null
        this.point.addArticle(article);
    }
}


const PointNewComponent = {
    controller: PointNewController,
    template: pointFormTemplate
}


export default PointNewComponent;
