import { extend } from 'angular';

import util from './util';


/**
 * A factory that returns a factory that's used to create configured
 * Search objects.
 *
 * The returned factory must be called with a resource type (an
 * ngResource type) and can passed the following options:
 *
 *     - {String} siteName [appConfig.mainSiteName]
 *     - {Boolean} delayed [true]
 *     - {Number} delay [300]
 *     - {Number} minChars [2]
 *     - {Object} defaultParams [{}]
 *     - {Function} successCallback [default is a no-op]
 *     - {Function} errorCallback [default logs to console]
 *
 * The success callback is called after a resource query is
 * resolved. For delayed searches, this provides a convenient
 * way to get search results back into the calling context.
 *
 * To use this in a component, say, do something like this:
 *
 *
 *     class MyComponent {
 *         static $inject = ['searchFactory', 'SomeResourceType'];
 *
 *         constructor (private searchFactory, private SomeResourceType) {
 *             this.resourceSearch = searchFactory(SomeResourceType, {
 *                 successCallback: (results) => {
 *                     this.searchResults = results;
 *                 }
 *             });
 *         }
 *     }
 */
export default function searchFactoryFactory ($timeout, appConfig) {
    const defaultConfig = {
        siteName: appConfig.mainSiteName,
        delayed: false,
        delay: 300,
        minChars: 2,
        defaultParams: {},
        successCallback: function () {
            // Do nothing
        },
        errorCallback: function () {
            if (typeof console !== 'undefined') {
                console.error('Search failed');
            }
        }
    };

    return function make (resourceType, config) {
        config = extend(defaultConfig, config);
        return new Search($timeout, resourceType, config);
    }
}

class Search {
    $timeout;
    currentTimeout;
    resourceType;
    config;

    constructor ($timeout, resourceType, config) {
        this.$timeout = $timeout;
        this.resourceType = resourceType;
        this.config = config;
    }

    getById (id, siteName?) {
        const params = {
            id: id,
            site_name: util.default(siteName, this.config.siteName)
        };
        return this.resourceType.get(params);
    }

    /**
     * If the search is delayed, this will return a promise;
     * otherwise it will return an Array of objects.
     *
     * @returns {Promise|resourceType[]}
     */
    search (params, options: {delayed?: boolean, delay?: number} = {}) {
        options.delayed = util.default(options.delayed, this.config.delayed);
        if (!options.delayed) {
            return this._search(params);
        } else {
            options.delay = util.default(options.delay, this.config.delay);
            this.$timeout.cancel(this.currentTimeout);
            this.currentTimeout = this.$timeout(() => {
                return this._search(params);
            }, options.delay);
            return this.currentTimeout;
        }
    }

    /**
     * Query the resource and return an Array of objects.
     *
     * @param {Object} [params={}]
     * @returns {resourceType[]}
     */
    private _search (params) {
        params = extend({}, this.config.defaultParams, params);
        params.site_name = util.default(params.site_name, this.config.siteName);
        return this.resourceType.query(
            params, this.config.successCallback, this.config.errorCallback);
    }

    /**
     * Search by a specific field.
     *
     * By default, these searches require a minimum number of
     * characters and will be delayed. This configuration is geared
     * toward auto-complete use cases.
     *
     * @param {String} name Name of field to search on
     * @param {String} value Value to search for
     * @param {Object} [params={}]
     * @param {Object} [options={}]
     * @returns {Promise|Object[]}
     */
    searchBy (name, value, params,
              options: {delayed: boolean, minChars?: number} = {delayed: true}) {
        params = params || {};
        params[name] = value;
        options.minChars = util.default(options.minChars, this.config.minChars);
        if (options.minChars && value.length < options.minChars) {
            return null;
        }
        return this.search(params, options);
    }
}
