import 'angular';
import 'angular-cookies';
import 'angular-material';
import 'angular-resource';
import 'angular-route';

import { bootstrap, module } from 'angular';

import util from './util';

import AppComponent from './app';
import ArticlesComponent from './articles/articles.component';
import ArticleComponent from './articles/article.component';

import PointComponent from './points/point.component';
import { PointFormComponent } from './points/point.component';
import PointsComponent from './points/points.component';
import PointNewComponent from './points/point.component.new';

import articleFactory from './articles/article';
import pointFactory from './points/point';

import ArticleFetchService from './articles/fetch.service';
import ArticleSearchService from './articles/search.service';
import { ArticleFilterDataService } from './articles/search.service';
import { ArticleMultiSelectComponent } from './articles/components';

import MapComponent from './map/map';
import { wfsSourceFactoryFactory, Map } from './map/map';

import { GeocoderComponent, GeocoderService } from './geocoder/geocoder';

import notifierFactory from './notifier';
import resourceFactoryFactory from './resource';
import searchFactoryFactory from './search';


// Global styles
import 'ol/ol.css';
import './styles.scss';


class HttpErrorInterceptor {
    static $inject = ['$q', '$window', 'appConfig', 'notifier'];

    constructor ($q, $window, appConfig, notifier) {
        return {
            responseError: function (response) {
                const config = response.config;
                const status = response.status;

                let currentLocation;
                let newLocation;
                let message;

                if (status === 400 || status === 404) {
                    // Do nothing for now
                } else if (status === 401) {
                    currentLocation = $window.location.href;
                    newLocation = util.url('/accounts/login/?next=');
                    newLocation = [newLocation, encodeURIComponent(currentLocation)].join('');
                    $window.location.href = newLocation;
                } else if (status >= 500) {
                    if (appConfig.debug) {
                        message = [config.method, config.url, '=>', status];
                        if (config.params) {
                            message.push('<br>Params:<ul>')
                            for (let k in config.params) {
                                message.push('<li>', k, '=>', config.params[k], '</li>');
                            }
                            message.push('</ul>');
                        }
                        message = message.join(' ');
                    } else {
                        message = 'Something unexpected happened';
                    }
                    notifier.error(message, 'Request Failed');
                }
                return $q.reject(response);
            }
        };
    }
}

function config ($compileProvider, $httpProvider, $locationProvider, $resourceProvider,
                 $routeProvider, $mdThemingProvider, appConfig) {
    if (!appConfig.debug) {
        // Disable debug data in production.
        // See https://docs.angularjs.org/guide/production.
        $compileProvider.debugInfoEnabled(false);
    }

    // These two lines will cause Angular to pull the CSRF token
    // from the CSRF cookie set by Django and add it to request
    // headers.
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
    $httpProvider.interceptors.push('httpErrorInterceptor');

    $locationProvider.hashPrefix('');

    $resourceProvider.defaults.stripTrailingSlashes = false;

    $routeProvider
        // Articles
        .when('/', {})

        .when('/search', {
            template: '<wayfinder-articles></wayfinder-articles>'
        })

        // Article
        .when('/articles/:site_name/:id', {
            template: '<wayfinder-article></wayfinder-article>'
        })

        // Points
        .when('/points', {
            template: '<wayfinder-points></wayfinder-points>'
        })
        .when('/points/:site_name/:id', {
            template: '<wayfinder-point></wayfinder-point>'
        })
        .when('/admin/points/new', {
            template: '<wayfinder-point-new></wayfinder-point-new>'
        })
        .when('/admin/points/:id/:action', {
            template: '<wayfinder-point-form></wayfinder-point-form>'
        })

        .when('/not-found', {
            template: '<not-found></not-found>'
        })

        .otherwise('/not-found');

    $mdThemingProvider.definePalette('wayfinder', {
        '50': '#e8eaf6',
        '100': '#c5cae9',
        '200': '#9fa8da',
        '300': '#7986cb',
        '400': '#5c6bc0',
        '500': '#012639',  // Primary
        '600': '#3949ab',
        '700': '#303f9f',
        '800': '#283593',
        '900': '#1a237e',
        'A100': '#8c9eff',
        'A200': '#536dfe',
        'A400': '#3d5afe',
        'A700': '#304ffe',
        'contrastDefaultColor': 'light',
        'contrastDarkColors': '50 100 200 A100',
        'contrastStrongLightColors': '300 400 A200 A400'
    });

    $mdThemingProvider.theme('default').primaryPalette('wayfinder').accentPalette('orange');
}


module(
    'wayfinder', [
        'ngCookies',
        'ngMaterial',
        'ngResource',
        'ngRoute'
    ])
    .constant('appConfig', WAYFINDER_CONFIG)
    .config([
        '$compileProvider', '$httpProvider', '$locationProvider', '$resourceProvider',
        '$routeProvider', '$mdThemingProvider', 'appConfig', config])

    .component('wayfinderApp', AppComponent)

    .component('wayfinderArticle', ArticleComponent)
    .component('wayfinderArticles', ArticlesComponent)
    .component('wayfinderArticleMultiSelect', ArticleMultiSelectComponent)

    .component('wayfinderPoint', PointComponent)
    .component('wayfinderPointForm', PointFormComponent)
    .component('wayfinderPoints', PointsComponent)
    .component('wayfinderPointNew', PointNewComponent)

    .component('wayfinderGeocoder', GeocoderComponent)
    .service('geocoder', GeocoderService)

    .component('wayfinderInputClearButton', {
        bindings: {
            icon: '@',
            title: '@',
            model: '=',
            action: '&'
        },
        controller: function () {
            this.icon = this.icon || 'clear';
            this.title = this.title || 'Clear';
            this.clear = function () {
                this.model = null;
                if (this.action) {
                    this.action();
                }
            }
        },
        template: `
            <md-icon title="{{ $ctrl.title }}"
                     style="position: absolute; right: 0; cursor: pointer;"
                     ng-if="$ctrl.model"
                     ng-click="$ctrl.clear();">{{ $ctrl.icon }}</md-icon>
        `
    })

    .component('notFound', {
        template: `
            <md-content class="standard-padding">
                <h1>Not Found</h1>
                <p>That page could not be found.</p>
            </md-content>
        `
    })

    .factory('Article', ['$sce', 'resourceFactory', 'Point', articleFactory])
    .factory('Point', ['$q', 'resourceFactory', 'appConfig', 'map', pointFactory])

    .factory('notifier', notifierFactory)
    .factory('resourceFactory', ['$resource', resourceFactoryFactory])
    .factory('searchFactory', ['$timeout', 'appConfig', searchFactoryFactory])

    .service('articleFetchService', ArticleFetchService)
    .service('articleSearchService', ArticleSearchService)
    .service('articleFilterData', ArticleFilterDataService)

    .component('wayfinderMap', MapComponent)
    .factory('wfsSourceFactory', ['appConfig', wfsSourceFactoryFactory])
    .service('map', Map)

    .service('httpErrorInterceptor', HttpErrorInterceptor);


bootstrap(document.body, ['wayfinder'], {
    strictDi: true
});
