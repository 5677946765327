import { boundingExtent, buffer as bufferExtent, getCenter as getCenterOfExtent } from 'ol/extent';

import { bbox as bboxLoadingStrategy } from 'ol/loadingstrategy';

import { transform, transformExtent } from 'ol/proj';

import { register as registerProj4 } from 'ol/proj/proj4';

import BaseMap from 'ol/Map';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import View from 'ol/View';

import BingMapsSource from 'ol/source/BingMaps';
import ClusterSource from 'ol/source/Cluster';
import OSMSource from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';

import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';

import AttributionControl from 'ol/control/Attribution';
import ScaleLineControl from 'ol/control/ScaleLine';

import { defaults as defaultInteractions } from 'ol/interaction';
import ModifyInteraction from 'ol/interaction/Modify';

import GeoJSON from 'ol/format/GeoJSON';

import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import proj4 from 'proj4';

import util from '../util';
import * as mapTemplate from './map.html';


registerProj4(proj4);


const pointRadius = 6;
const clusterDistance = 0.75 * pointRadius;


const styleMap = {
    point: new Style({
        image: new Circle({
            radius: pointRadius,
            stroke: new Stroke({
                color: 'rgb(255,255,255)',
                width: 2
            }),
            fill: new Fill({
                color: 'rgba(53,75,92,0.8)',
            })
        })
    }),

    highlighted: new Style({
        image: new Icon({
            src: '/assets/images/map/markers/highlighted.png',
            size: [28, 28],
            anchor: [0.5, 0.5]
        })
        // image: new Circle({
        //     radius: 12,
        //     stroke: new Stroke({
        //         color: 'rgba(68, 83, 92, 0.5)',
        //         width: 8
        //     }),
        //     fill: new Fill({
        //         color: 'rgb(184,61,36)'
        //     })
        // })
    }),

    selected: new Style({
        image: new Icon({
            src: '/assets/images/map/markers/circle-selected.png',
            size: [36, 36],
            anchor: [0.5, 0.5]
        })
    }),

    redDot: new Style({
        image: new Circle({
            radius: pointRadius,
            stroke: new Stroke({
                color: 'rgb(1,38,57)',
                width: 1
            }),
            fill: new Fill({
                color: 'rgb(255,0,0)'
            })
        })
    })
};


export class Map extends BaseMap {
    static $inject = ['$http', '$location', '$rootScope', 'appConfig', 'wfsSourceFactory'];

    baseLayers;
    selectedBaseLayer;

    initialCenter;
    initialExtent;

    defaultZoom;

    featureSources;
    featureLayers;
    modifiableFeatures;
    styleMap;

    featurePopup;
    highlightOverlay;
    selectedOverlay;

    constructor (private $http,
                 private $location,
                 private $rootScope,
                 private appConfig,
                 private wfsSourceFactory) {

        super({
            controls: new Collection().extend([
                new ScaleLineControl(),
                new AttributionControl({
                    collapsible: false
                })
            ]),
            interactions: defaultInteractions({
                dragRotate: false,
                pinchRotate: false
            })
        });

        const mapOptions = appConfig.mapOptions;
        const bingMapsKey = appConfig.bing.key;

        this.baseLayers = [
            new TileLayer({
                label: 'Road Map',
                shortLabel: 'Map',
                source: new BingMapsSource({
                    key: bingMapsKey,
                    imagerySet: 'Road'
                })
            }),
            new TileLayer({
                label: 'Satellite/Aerial',
                shortLabel: 'Satellite',
                visible: false,
                source: new BingMapsSource({
                    key: bingMapsKey,
                    imagerySet: 'Aerial'
                })
            }),
            new TileLayer({
                label: 'Hybrid',
                shortLabel: 'Hybrid',
                visible: false,
                source: new BingMapsSource({
                    key: bingMapsKey,
                    imagerySet: 'AerialWithLabels'
                })
            }),
            new TileLayer({
                label: 'OpenStreetMap',
                shortLabel: 'OSM',
                visible: false,
                source: new OSMSource()
            })
        ];

        this.selectedBaseLayer = this.baseLayers[0];
        //Changing default zoom
        //this.defaultZoom = mapOptions.defaultZoom;
        this.defaultZoom = 7;
        mapOptions.initialZoom = 7;
        

        this.featurePopup = this.createPopup();

        this.featureSources = {
            oep: wfsSourceFactory({
                siteName: 'oep',
                layers: ['points']
            }),
            ohp: wfsSourceFactory({
                siteName: 'ohp',
                layers: ['points']
            })
        };

        this.featureLayers = [
            new VectorLayer({
                projection: 'EPSG:3857',
                source: new ClusterSource({
                    distance: clusterDistance,
                    source: this.featureSources.oep
                }),
                style: styleMap.point
            }),
            new VectorLayer({
                projection: 'EPSG:3857',
                source: new ClusterSource({
                    distance: clusterDistance,
                    source: this.featureSources.ohp
                }),
                style: styleMap.point
            })
        ];

        // Features in this overlay will be highlighted.
        // This is for, e.g, highlighting an article's points.
        this.highlightOverlay = new VectorLayer({
            source: new VectorSource({
                features: new Collection()
            }),
            style: styleMap.highlighted
        });

        this.selectedOverlay = new VectorLayer({
            source: new VectorSource({
                features: new Collection()
            }),
            style: styleMap.selected
        });

        const modifiableFeatures = new Collection();
        const modifyInteraction = new ModifyInteraction({
            features: modifiableFeatures,
            deleteCondition: () => false,
            style: styleMap.redDot
        });

        this.modifiableFeatures = modifiableFeatures;
        this.styleMap = styleMap;

        this.initialExtent = this.transformExtent(mapOptions.initialExtent);
        this.initialCenter = (
            mapOptions.center ?
                this.transform(mapOptions.center) :
                getCenterOfExtent(this.initialExtent)
        );

        this.baseLayers.forEach((layer) => {
            super.addLayer(layer);
        });

        super.addInteraction(modifyInteraction);

        this.featureLayers.forEach((layer) => {
            super.addLayer(layer);
        });
        super.addLayer(this.highlightOverlay);
        super.addLayer(this.selectedOverlay);

        super.addOverlay(this.featurePopup);

        // Show popup on hover
        super.on('pointermove', (event) => {
            let feature = super.forEachFeatureAtPixel(event.pixel, (feature) => {
                return feature;
            });
            if (feature) {
                let cluster = feature.get('features');
                if (cluster) {
                    if (cluster.length > 1) {
                        this.showClusterPopup(feature, cluster);
                        return;
                    }
                    feature = cluster[0];
                }
                this.showFeaturePopup(feature);
            } else {
                this.hideFeaturePopup();
            }
        }, this);

        // When the user clicks on a point...
        // - if it's a cluster point, zoom into the extent of the points
        //   in the cluster.
        // - if it's a single point, go to the associated article.
        super.on('click', function (event) {
            let feature = this.forEachFeatureAtPixel(event.pixel, (feature) => {
                return feature;
            });

            this.hideFeaturePopup();

            if (feature) {
                let cluster = feature.get('features');

                if (cluster) {
                    if (cluster.length > 1) {
                        this.fitExtentOfFeatures(cluster);
                        return;
                    }
                    feature = cluster[0];
                }

                let siteName = feature.get('site_name');
                let articles = feature.get('articles');

                if (siteName && articles && articles.length > 0) {
                    let articleUrl = util.pathJoin('/articles', siteName, articles[0]);
                    $rootScope.$apply(() => {
                        $location.url(articleUrl);
                    });
                } else {
                    console.log('Feature has no articles:', feature)
                }
            }
        }, this);

        super.setView(new View ({
            center: this.initialCenter,
            enableRotation: false,
            maxZoom: mapOptions.maxZoom,
            minZoom: mapOptions.minZoom,
            zoom: mapOptions.initialZoom
        }));
    }

    switchLayer (selectedLayer) {
        const baseLayers = this.baseLayers;
        let i, len, layer, visible;
        if (selectedLayer === this.selectedBaseLayer) {
            return false;
        }
        for (i = 0, len = baseLayers.length; i < len; ++i) {
            layer = baseLayers[i];
            if (selectedLayer === layer) {
                visible = true;
                this.selectedBaseLayer = layer;
            } else {
                visible = false;
            }
            layer.set('visible', visible);
        }
        return true;
    }

    createPopup () {
        let title = document.createElement('div');
        let content = document.createElement('div');
        let container = document.createElement('div');
        let popup;

        title.setAttribute('class', 'title');
        content.setAttribute('class', 'content');
        container.setAttribute('class', 'map-popup');
        container.appendChild(title);
        container.appendChild(content);

        popup = new Overlay({
            element: container,
            offset: [6, 6]
        });

        popup.setTitle = (title_) => title.innerHTML = title_;
        popup.setContent = (content_) => content.innerHTML = content_;
        popup.setAll = (title, content, position) => {
            popup.setTitle(title);
            popup.setContent(content);
            popup.setPosition(position);
        };

        return popup;
    }

    showFeaturePopup (feature) {
        if (feature && typeof feature.getId() !== 'undefined') {
            const geom = feature.getGeometry();
            const position = geom.getCoordinates();
            const title = feature.get('name');
            const description = feature.get('description');
            const content = description ? `<p>${description}</p>` : '';
            this.featurePopup.setAll(title, content, position);
        }
    }

    showClusterPopup (feature, features) {
        const title = 'Multiple points here';
        const content = 'Click to zoom in';
        const position = feature.getGeometry().getCoordinates();
        this.featurePopup.setAll(title, content, position);
    }

    hideFeaturePopup () {
        this.featurePopup.setPosition();
    }

    getFeatureSource (siteName) {
        return this.featureSources[siteName];
    }

    /**
     * Reload feature source inside extent.
     *
     * @param {String} siteName
     * @param {ol.Extent} extent
     * @param {Boolean} [clear=false] Clear existing features in extent?
     */
    reloadFeatureSource (siteName, extent, clear) {
        const source = this.getFeatureSource(siteName),
              extentPassed = !!extent;
        let featuresToRemove;
        extent = extent || source.getExtent();
        if (clear) {
            if (extentPassed) {
                featuresToRemove = source.getFeaturesInExtent(extent);
                if (featuresToRemove.length) {
                    for (let i = 0, len = featuresToRemove.length; i < len; ++i) {
                        source.removeFeature(featuresToRemove[i]);
                    }
                }
            } else {
                source.clear(true);
            }
        }
        source.refresh();
    }

    addFeature (siteName, feature) {
        this.getFeatureSource(siteName).addFeature(feature);
    }

    getFeature (siteName, featureId) {
        const source = this.getFeatureSource(siteName);
        return source.getFeatureById(featureId);
    }

    getFeatures (siteName, featureIds) {
        const source = this.getFeatureSource(siteName),
              features = [];
        for (let featureId, feature, i = 0, len = featureIds.length; i < len ; ++i) {
            featureId = featureIds[i];
            feature = source.getFeatureById(featureId);
            features.push(feature);
        }
    }

    removeFeature (siteName, feature) {
        const source = this.getFeatureSource(siteName);
        if (!(feature instanceof Feature)) {
            feature = source.getFeatureById(feature);
        }
        source.removeFeature(feature);
    }

    highlightFeature (feature, zoomTo) {
        this.highlightOverlay.getSource().addFeature(feature);
        if (zoomTo) {
            this.setCenterAtFeature(feature);
            this.zoomInToDefault();
        }
    }

    unHighlightFeature (feature) {
        this.highlightOverlay.getSource().removeFeature(feature);
    }

    highlightFeatures (features, fitExtent: boolean = false) {
        let source;
        if (features.length) {
            source = this.highlightOverlay.getSource();
            source.clear(true);
            source.addFeatures(features);
            if (fitExtent) {
                this.fitExtentOfFeatures(features);
            }
        }
    }

    clearHighlightedFeatures () {
        this.highlightOverlay.getSource().clear(true);
    }

    selectFeature (feature, zoomTo) {
        this.selectedOverlay.getSource().addFeature(feature);
        if (zoomTo) {
            this.setCenterAtFeature(feature);
            this.zoomInToDefault();
        }
    }

    unSelectFeature (feature) {
        this.selectedOverlay.getSource().removeFeature(feature);
    }

    selectFeatures (features, fitExtent /* =false */) {
        let source;
        if (features.length) {
            source = this.selectedOverlay.getSource();
            source.clear(true);
            source.addFeatures(features);
            if (fitExtent) {
                this.fitExtentOfFeatures(features);
            }
        }
    }

    clearSelectedFeatures () {
        this.selectedOverlay.getSource().clear(true);
    }

    fitExtent (extent: Array<number>, native: boolean = true, buffer?: number) {
        if (!native) {
            extent = this.transformExtent(extent);
        }
        if (buffer) {
            extent = bufferExtent(extent, buffer);
        }
        super.getView().fit(extent);
    }

    fitExtentOfCoordinates (coords, native?: boolean, buffer?: number) {
        this.fitExtent(boundingExtent(coords), native, buffer);
    }

    fitExtentOfFeatures (features, native?: boolean, buffer: number = 250) {
        this.fitExtent(this.getExtentOfFeatures(features), native, buffer);
    }

    getExtentOfFeatures (features) {
        const coords = [];
        for (let feature, i = 0, len = features.length; i < len ; ++i) {
            feature = features[i];
            coords.push(feature.getGeometry().getCoordinates());
        }
        return boundingExtent(coords);
    }

    /**
     * Set center to the specified coordinates, which are assumed to
     * be geographic [longitude, latitude].
     *
     * @param {ol.Coordinate[]} coords [x, y]
     * @param {Boolean} [native=false]
     */
    setCenter (coords, native: boolean = false) {
        if (!native) {
            coords = this.transform(coords);
        }
        super.getView().setCenter(coords);
    }

    setCenterToInitialCenter () {
        this.setCenter(this.initialCenter, true);
    }

    /**
     * Center the map at a feature's coordinates, which must be
     * native.
     *
     * @param {ol.Feature} feature
     */
    setCenterAtFeature (feature) {
        this.setCenter(feature.getGeometry().getCoordinates(), true);
    }

    setZoom (zoom) {
        super.getView().setZoom(zoom);
        console.log(zoom);
    }

    zoomRelative (delta) {
        const view = super.getView();
        const currentZoom = view.getZoom();
        const newZoom = currentZoom + delta;
        view.setZoom(newZoom);
    }

    zoomSlide(delta) {
        const view = super.getView();
        const newZoom = delta;
        view.setZoom(newZoom);
    }

    zoomToFullExtent () {
        this.fitExtent(this.initialExtent);
    }

    /**
     * Zoom in to the default zoom level unless the current zoom
     * level is greater than the default zoom level. This is used
     * to ensure the map is zoomed in to *at least* the default
     * zoom level.
     */
    zoomInToDefault () {
        const currentZoom = super.getView().getZoom();
        if (currentZoom < this.defaultZoom) {
            this.setZoom(this.defaultZoom);
        }
    }

    /**
     * Zoom out to the default zoom level unless the current zoom
     * level is less than the default zoom level. This is used to
     * ensure the map is zoomed out to *at least* the default zoom
     * level.
     */
    zoomOutToDefault () {
        const currentZoom = super.getView().getZoom();
        if (currentZoom > this.defaultZoom) {
            this.setZoom(this.defaultZoom);
        }
    }

    /**
     * Transform geographic coordinates to map coordinates.
     *
     * If reverse is specified, transform from map coordinates to
     * geographic coordinates.
     */
    transform (coords: Array<number>, reverse: boolean = false) {
        const projections = this._getTransformProjections(reverse);
        return transform(coords, projections.from, projections.to);
    }

    /**
     * Transform an extent from geographic to map projection.
     *
     * If reverse is specified, transform from map projection to
     * geographic projection.
     */
    transformExtent (extent: Array<number>, reverse: boolean = false) {
        const projections = this._getTransformProjections(reverse);
        return transformExtent(extent, projections.from, projections.to);
    }

    _getTransformProjections (reverse: boolean = false) {
        let fromProjection = 'EPSG:4326';
        let toProjection = 'EPSG:3857';
        if (reverse) {
            let tempProjection = fromProjection;
            fromProjection = toProjection;
            toProjection = tempProjection;
        }
        return {
            from: fromProjection,
            to: toProjection
        }
    }
}


export function wfsSourceFactoryFactory (appConfig) {
    let baseParams = 'srid=3857&format=geojson';

    return function wfsSourceFactory (options) {
        let source,
            siteName = options.siteName,
            host = appConfig.hosts[siteName],
            url = `//${host}/api/wayfinder/points/wfs/`,
            layers = options.layers,
            numLayers = layers.length,
            typeName = [],
            i, layer;

        for (i = 0; i < numLayers; ++i) {
            layer = layers[i];
            typeName.push([siteName, layer].join(':'));
        }

        source = new VectorSource({
            format: new GeoJSON(),
            label: siteName,
            strategy: bboxLoadingStrategy,
            url: (extent) => {
                const bbox = extent.join(',');
                const params = `bbox=${bbox}`;
                return `${url}?${baseParams}&${params}`;
            }
        });

        source.on('addfeature', (event) => {
            event.feature.set('siteName', siteName, /* silent= */ true);
        });

        return source;
    }
}


class MapComponentController {
    static $inject = ['map'];

    constructor (private map) {}

    $postLink () {
        this.map.setTarget('map');
    }
}


const MapComponent = {
    template: mapTemplate,
    controller: MapComponentController
};


export default MapComponent;
