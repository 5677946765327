import { extend, fromJson } from 'angular';

import util from './util';


export default function resourceFactoryFactory ($resource) {
    const defaultActions = {
        'get': {method: 'GET'},
        'save': {method: 'POST'},
        'query': {method: 'GET', isArray: true},
        'remove': {method: 'DELETE'},
        'delete': {method: 'DELETE'},
        'update': {method: 'PUT'},
        'patch': {method: 'PATCH'}
    };

    /**
     * Wrap Angular's $resource for convenience and consistency.
     *
     * Check the AngularJS docs on $resource for the basics. This is
     * pretty similar but adds a few things.
     *
     * The optional args for $resource are passed via an options object
     * instead of as positional args. The options arg to $resource is
     * ignored since we want all resources to use the same options.
     *
     * The built-in options are (see $resource docs for details):
     *
     *     - paramDefaults
     *     - actions
     *
     * The additional options are:
     *
     *     - transformer: Transforms resource instances; this happens
     *       *after* the Angular machinery instantiates the resource
     *       instance or instances.
     *     - prototype: Additional properties and methods for the
     *       resource. A special superPrototype property will be added
     *       to the prototype so overridden methods have a way to call
     *       super methods (in particular, this is useful if toJSON()
     *       needs to be overridden).
     *
     * For actions, these additional options are available:
     *
     *     - isPaginated: This implies isArray. When this is set,
     *       paginated results as returned from DRF will be handled
     *       correctly. The array of resources will have a pageInfo
     *       object attached.
     *
     * Also, when customizing a built-in action such as get or query,
     * it's not necessary to specify the method--the default method will
     * be used.
     *
     * Two additional actions are added by default: update (PUT) and
     * patch (PATCH).
     *
     * Action URLs are normalized using util.url().
     *
     * @param {String} url Default URL for actions
     * @param {Object} options Options for $resource plus some extras
     * @returns {Object} Resource constructor
     */
    return function resourceFactory (url, options) {
        const paramDefaults = options.paramDefaults,
              actions = extend({}, defaultActions, options.actions),
              transformer = options.transformer,
              prototype = options.prototype;

        let action,
            actionName,
            memberInterceptor,
            collectionInterceptor,
            resource;

        function transformPaginatedResponse (data) {
            let results;
            data = fromJson(data);
            if (data) {
                results = data.results;
                if (results) {
                    results.pageInfo = {
                        count: data.count,
                        previous: data.previous,
                        next: data.next
                    };
                }
            }
            return results;
        }

        url = util.url(url);

        if (transformer) {
            memberInterceptor = {
                response: function (response) {
                    return transformer(response.resource);
                }
            };
            collectionInterceptor = {
                response: function (response) {
                    const pageInfo = response.data.pageInfo,
                          results = response.resource;
                    if (pageInfo) {
                        results.pageInfo = pageInfo;
                    }
                    for (let i = 0, len = results.length; i < len; ++i) {
                        results[i] = transformer(results[i]);
                    }
                    return results;
                }
            };
        }

        for (actionName in actions) {
            if (actions.hasOwnProperty(actionName)) {
                action = actions[actionName];
                if (!action.method && defaultActions.hasOwnProperty(actionName)) {
                    action.method = defaultActions[actionName].method;
                }
                if (action.url) {
                    action.url = util.url(action.url);
                }
                if (action.isPaginated) {
                    action.isArray = true;
                    action.transformResponse = transformPaginatedResponse;
                }
                if (action.isArray) {
                    action.interceptor = action.interceptor || collectionInterceptor;
                } else {
                    action.interceptor = action.interceptor || memberInterceptor;
                }
            }
        }

        resource = $resource(url, paramDefaults, actions, {
            cancellable: true
        });

        if (prototype) {
            resource.prototype.superPrototype = extend({}, resource.prototype);
            extend(resource.prototype, prototype);
        }

        return resource;
    };
}
