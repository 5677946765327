import util from '../util';
import * as geocoderTemplate from './geocoder.html';


class GeocoderController {
    static $inject = ['geocoder', 'map'];

    results;
    error;

    constructor (private geocoder, private map) {
        this.results = [];
    }

    findAddress (address) {
        const result = this.geocoder.findAddress(address);
        if (!result) {
            return;
        }
        return result.then((response) => {
            this.error = null;
            this.results = response.data.results;
        }).catch((response) => {
            this.results = null;
            switch (response.status) {
                case 400:
                    this.error = 'Address lookup failed';
                    break;
                case 404:
                    this.error = 'Could not find address';
                    break;
                default:
                    this.error = 'A problem was encountered while trying to find that address';
            }
        });
    }

    centerOnResult (result) {
        this.map.setCenter(result.coordinates);
        this.map.zoomInToDefault();
    }
}


export const GeocoderComponent = {
    controller: GeocoderController,
    template: geocoderTemplate,
    transclude: true
}


export class GeocoderService {
    static $inject = ['$http'];

    url = util.serviceUrl('wayfinder/geocoder');

    constructor (private $http) {}

    findAddress (address) {
        if (!address) {
            return null;
        }
        return this.$http.get(this.url, {params: {location: address}});
    }
}
