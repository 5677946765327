export default class ArticleFetchService {
    static $inject = ['Article'];

    article = null;
    fetchInProgress = false;
    resourceType;

    constructor (resourceType) {
        this.resourceType = resourceType;
        this.reset();
    }

    fetch (params) {
        this.fetchInProgress = true;
        this.article = this.resourceType.get(params);
        this.article.$promise.finally(() => {
            this.fetchInProgress = false;
        });
        return this.article;
    }

    cancel () {
        if (this.article) {
            this.article.$cancelRequest();
        }
    }

    reset () {
        this.article = null;
        this.cancel();
    }
}
