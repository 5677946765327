import * as pointsTemplate from './templates/index.html';


class PointsController {
    static $inject = ['map', 'searchFactory', 'appConfig', 'Point'];

    user;
    points;

    pageSize = 10;
    numPages = null;
    currentPage = 1;
    previousPage = null;
    nextPage = null;

    name = null;
    count = null;
    defaultOrdering = 'name';
    ordering = 'name';

    pointSearch;

    constructor (private map, private searchFactory, private appConfig, private Point) {
        this.user = appConfig.user;

        this.pointSearch = searchFactory(Point, {
            defaultParams: {
                page_size: this.pageSize
            },
            successCallback: (results) => {
                const pageInfo = results.pageInfo;
                this.points = results;
                this.count = pageInfo.count;
                this.numPages = Math.ceil(this.count / this.pageSize);
                this.previousPage = pageInfo.previous ? this.currentPage - 1 : null;
                this.nextPage = pageInfo.next ? this.currentPage + 1 : null;
                this.fitExtent();
            },
            errorCallback: () => {
                this.points = null;
            }
        });

        this.map.clearHighlightedFeatures();
        this.getPage(this.currentPage);
    }

    private getOrderingParam () {
        let ordering = this.ordering;
        if (this.ordering !== 'name') {
            ordering = `${ordering},name`;
        }
        return ordering;
    }

    getPage (page) {
        let params = {
            name: null,
            page: null,
            ordering: this.getOrderingParam()
        };
        if (typeof page !== 'undefined') {
            page = parseInt(page, 10);
            params.page = page;
        } else {
            page = 1;
        }
        if (this.name) {
            params.name = this.name;
        }
        this.currentPage = page;
        this.pointSearch.search(params);
    }

    filterByName (name) {
        const params = {
            name: name || this.name,
            ordering: this.getOrderingParam()
        };
        const options = {
            delayed: true
        };
        this.currentPage = 1;
        this.pointSearch.search(params, options);
    }

    reset () {
        this.name = '';
        this.ordering = this.defaultOrdering;
        this.getPage(1);
    }

    fitExtent () {
        let coords = [];
        if (this.points.length) {
            for (let point, i = 0; i < this.points.length; ++i) {
                point = this.points[i];
                coords.push([point.long, point.lat]);
            }
            this.map.fitExtentOfCoordinates(coords, false);
        } else {
            this.map.zoomToFullExtent();
        }
    }
}


const PointsComponent = {
    controller: PointsController,
    template: pointsTemplate
}


export default PointsComponent;
