import { isUndefined, toJson } from 'angular';

import GeoJSON from 'ol/format/GeoJSON';

import util from '../util';


export default function pointFactory ($q, resourceFactory, appConfig, map) {
    const baseUrl = util.serviceUrl('wayfinder/points');
    const baseSearchUrl = util.pathJoin(baseUrl, 'search/:site_name/');
    const geojsonFormat = new GeoJSON({
        dataProjection: 'EPSG:3857'
    });

    return resourceFactory(util.pathJoin(baseUrl, ':id'), {
        transformer: function (point) {
            point.featureId = [point.site_name, 'points', point.id].join('.');
            point.path = ['/points', point.site_name, point.id].join('/');
            return point;
        },
        paramDefaults: {
            id: '@id'
        },
        actions: {
            get: {
                url: util.pathJoin(baseSearchUrl, ':id'),
                params: {
                    site_name: '@site_name',
                    id: '@id'
                }
            },
            query: {
                url: util.pathJoin(baseSearchUrl),
                params: {
                    site_name: appConfig.mainSiteName
                },
                isPaginated: true
            }
        },
        prototype: {
            toJSON: function () {
                let data = this.superPrototype.toJSON.call(this);
                delete data.feature;
                delete data.featureId;
                delete data.path;
                return data;
            },

            addArticle: function (article) {
                this.articles.push({
                    id: article.id,
                    slug: article.slug,
                    title: article.title
                });
            },

            removeArticle: function (article) {
                let articles = this.articles;
                for (let a, i = 0, len = articles.length; i < len; ++i) {
                    a = articles[i];
                    if (a.id === article.id) {
                        articles.splice(i, 1);
                        break;
                    }
                }
            },

            /**
             * Get the feature associated with this point.
             *
             * Converts raw GeoJSON data to an OpenLayers feature.
             *
             * @returns {ol.Feature}
             */
            getFeature: function () {
                return geojsonFormat.readFeature(this.feature);
            },

            setFeature: function (feature) {
                this.feature = feature;
                this.featureId = feature.getId();
            },

            getCoordinates: function (native: boolean = true) {
                let coords = [this.long, this.lat];
                if (native) {
                    coords = map.transform(coords);
                }
                return coords;
            },

            setCoordinates: function (coords, native /* =false */) {
                if (native) {
                    coords = map.transform(coords, true);
                }
                this.long = parseFloat(coords[0].toFixed(5));
                this.lat = parseFloat(coords[1].toFixed(5));
            },

            showOnMap: function (zoom) {
                map.setCenter([this.long, this.lat]);
                if (isUndefined(zoom)) {
                    // Zoom in to default level when no zoom is specified
                    // unless already zoomed in past default level.
                    // zoom === null keeps current zoom.
                    map.zoomInToDefault();
                }
            }
        }
    });
}
