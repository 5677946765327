import { toJson } from 'angular';

import util from '../util';

export default function articleFactory ($sce, resourceFactory, Point) {
    const baseUrl = util.serviceUrl('articles');
    const baseSearchUrl = util.pathJoin(baseUrl, 'search/:site_name/');

    return resourceFactory(util.pathJoin(baseUrl, ':id'), {
        transformer: function (article) {
            const domainName = article.domain_name;
            const siteName = article.site_name;
            function makeFullyQualified (path) {
                if (!path) {
                    return path;
                }
                path = util.stripLeadingSlashes(path);
                return ['//', domainName, '/', path].join('');
            }
            article.path = util.pathJoin('/articles', siteName, article.id);
            if (siteName === 'oep') {
                article.site_title = 'Oregon Encyclopedia';
            } else if (siteName === 'ohp') {
                article.site_title = 'Oregon History Project';
            }
            article.teaser = $sce.trustAsHtml(article.teaser);
            
            article.url = makeFullyQualified(article.url);
            if(document.getElementById('isKiosk-wayfinder')) {
              article.url = '';
            }
            //article.thumbnail_url = makeFullyQualified(article.thumbnail_url);
            //article.thumbnail_medium_url = makeFullyQualified(article.thumbnail_medium_url);
            //Check if the thumbnail is using the default
            let imagePath = util.stripLeadingSlashes(article.thumbnail_url);
            if( imagePath.replace(/^.*[\\\/]/, '') !== 'default-thumbnail.png' ){
                article.thumbnail_url = makeFullyQualified(article.thumbnail_url);
                article.thumbnail_medium_url = makeFullyQualified(article.thumbnail_medium_url);
            }else{
                article.thumbnail_url = '';
                article.thumbnail_medium_url = '';
            }
            
            for (let point, i = 0, len = article.points.length; i < len; ++i) {
                point = article.points[i];
                point.featureId = ['points', point.id].join('.');
                point.site_name = siteName;
                point.path = ['/points', point.site_name, point.id].join('/');
                article.points[i] = new Point(point);
            }
            return article;
        },
        paramDefaults: {
            site_name: '@site_name',
            id: '@id'
        },
        actions: {
            get: {
                url: util.pathJoin(baseSearchUrl, ':id')
            },
            query: {
                url: util.pathJoin(baseSearchUrl),
                isPaginated: true
            },
            patchPoints: {
                method: 'PATCH',
                transformRequest: function (resource) {
                    let points;
                    if (resource.points) {
                        points = resource.points.map(point => point.id);
                    }
                    return toJson({'point_ids': points});
                }
            }
        },
        prototype: {
            /**
             * Find the specified Point in this Article's points
             * collection. If the Point is found, return the index where
             * it was found; if it's not, return -1.
             *
             * @param {Point} point
             * @returns {Number} index
             */
            findPoint: function (point) {
                const points = this.points;
                for (let p, i = 0, len = points.length; i < len; ++i) {
                    p = points[i];
                    if (p.id === point.id) {
                        return i;
                    }
                }
                return -1;
            },

            /**
             * Add a Point to this Article's points collection. If the
             * Point is already present in the collection, do nothing
             * and return null.
             *
             * @param {Point} point
             * @returns {Point}
             */
            addPoint: function (point) {
                const index = this.findPoint(point);
                if (index >= 0) {
                    return null;
                }
                this.points.push(point);
                return point;
            },

            /**
             * Remove the specified Point from this Article's points
             * collection. If the Point is in the collection, true will
             * be returned; if not, false will be returned.
             *
             * @param {Point} point
             * @returns {Boolean} Whether Point was removed
             */
            removePoint: function (point) {
                const index = this.findPoint(point);
                if (index >= 0) {
                    this.points.splice(index, 1);
                    return true;
                }
                return false;
            }
        }
    });
}
