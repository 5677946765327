import { element, isDefined } from 'angular';

import { unByKey } from 'ol/Observable';

import * as pointTemplate from './templates/show.html';
import * as pointFormTemplate from './templates/form.html';


class PointController {
    static $inject = [
        '$scope', '$location', '$route', '$routeParams', '$mdDialog', 'appConfig', 'map',
        'searchFactory', 'Point', 'Article'];

    user;
    point;

    mainSiteName;

    formConfig;
    formErrors;

    articleSearch = null;
    articleSearchTitle = null;
    articleSearchResults = null

    constructor (private $scope, private $location, private $route, private $routeParams,
                 private $mdDialog, private appConfig, private map, private searchFactory,
                 private Point, private Article) {
        const mainSiteName = appConfig.mainSiteName;

        // Don't update the point if Enter is pressed while search for articles
        element(document.getElementById('point-article')).on('keypress', function (event) {
            if (event.which === 13) {
                event.preventDefault();
                return false;
            }
            return true;
        });

        this.user = appConfig.user;

        this.mainSiteName = mainSiteName;

        this.formConfig = {
            type: 'edit',
            submitValue: 'Update Point',
            title: 'Edit Point'
        };
        this.formErrors = null;

        this.articleSearch = searchFactory(Article, {
            successCallback: function (results) {
                this.articleSearchResults = results;
            }.bind(this),
            errorCallback: function () {
                this.articleSearchResults = null;
            }.bind(this)
        });

        this.point = Point.get({
            site_name: $routeParams.site_name || mainSiteName,
            id: $routeParams.id
        }, (point) => {
            const feature = point.getFeature();
            map.clearHighlightedFeatures();
            map.highlightFeature(feature, true);
            $scope.$on('$destroy', function () {
                map.unHighlightFeature(feature);
            });
            if ($routeParams.action === 'edit') {
                const listenerKey = feature.on('change', function (event) {
                    point.setCoordinates(event.target.getGeometry().getCoordinates(), true);
                    $scope.$digest();
                });
                map.modifiableFeatures.push(feature);
                $scope.$on('$destroy', function () {
                    unByKey(listenerKey);
                    map.modifiableFeatures.clear();
                });
            }
        });
    }

    updateCoordinates () {
        const point = this.point;
        if (isDefined(point.long) && isDefined(point.lat)) {
            point.showOnMap();
        }
    }

    save () {
        const $location = this.$location,
              action = this.point.id ? '$update' : '$save';
        this.point[action](
            function (point) {
                $location.url(point.path);
            }.bind(this),
            function (response) {
                this.formErrors = response.data;
            }.bind(this)
        );
    }

    cancel () {
        this.$location.path('/points');
    }

    delete () {
        const $location = this.$location,
              mainSiteName = this.mainSiteName,
              map = this.map,
              point = this.point,
              confirm = this.$mdDialog.confirm({
                  title: ['Delete point "', point.name, '"?'].join(''),
                  ok: 'Yes',
                  cancel: 'No'
              });
        this.$mdDialog.show(confirm).then(function () {
            point.$delete(function () {
                $location.path('/points');
                map.removeFeature(mainSiteName, point.featureId);
            });
        });
    }

    addArticle (article) {
        this.articleSearchTitle = null;
        this.articleSearchResults = null
        this.point.addArticle(article);
    }
}



const PointComponent = {
    controller: PointController,
    template: pointTemplate
}


export const PointFormComponent = {
    controller: PointController,
    template: pointFormTemplate
}


export default PointComponent;
