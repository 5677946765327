class ArticleMultiSelectController {
    static $inject = ['articleSearchService'];

    constructor (private searchService) {}
}


export const ArticleMultiSelectComponent = {
    bindings: {
        label: '@',
        selections: '=',
        options: '='
    },
    controller: ArticleMultiSelectController,
    template: `
        <md-input-container>
            <label>
                <b>{{ $ctrl.label }}</b> - 
                {{ $ctrl.selections.length ? $ctrl.selections.length + " selected" : "Any" }}
            </label>
            <md-select multiple ng-model="$ctrl.selections">
                <md-option ng-repeat="option in $ctrl.options.slice(1)" ng-value="option.value">
                    {{ option.label }}
                </md-option>
            </md-select>
        </md-input-container>
        `
}
