import util from '../util';


export default class ArticleSearchService {

    static $inject = ['$location', 'Article', 'articleFilterData'];

    filters;

    // Search filters
    searchText = null;
    filterSelections = null;

    // Current result set
    results = null;

    // Pagination-related
    pageSize = 10;
    totalResults = null;
    numPages = null;
    currentPage = 1;
    previousPage = null;
    nextPage = null;
    range = null;

    inSearchMode = false;
    searchInProgress = false;

    constructor (private $location, private resourceType, private filterData) {
        this.filterData.then((filters) => {
            this.filters = filters;
        });
    }

    search (filters) {
        this.searchInProgress = true;
        this.cancel();

        return this.filterData.then(() => {
            filters = filters || this.getSearchFiltersFromProperties();
            filters.has_points = '1';
            filters.limit = '0';
            filters.page = this.currentPage;
            filters.i = '*';
            this.results = this.resourceType.query(filters);
            this.results.$promise.then((results) => {
                let pageInfo = results.pageInfo,
                    totalResults = pageInfo.count,
                    rangeStart = (this.currentPage - 1) * this.pageSize + 1,
                    rangeEnd = rangeStart + this.pageSize - 1;
                if (rangeEnd > totalResults) {
                    rangeEnd = totalResults;
                }
                this.inSearchMode = results.length > 1;
                this.totalResults = totalResults;
                this.numPages = Math.ceil(this.totalResults / this.pageSize);
                this.previousPage = pageInfo.previous ? this.currentPage - 1 : null;
                this.nextPage = pageInfo.next ? this.currentPage + 1 : null;
                this.range = [rangeStart, rangeEnd];
            }).catch(() => {
                this.inSearchMode = false;
            }).finally(() => {
                this.searchInProgress = false;
            });
            return this.results;
        });
    }

    getSearchFiltersFromProperties () {
        let params = {
            page: null,
            q: null
        };

        if (this.currentPage && this.currentPage !== 1) {
            params.page = this.currentPage;
        }

        if (this.searchText) {
            params.q = this.searchText;
        }

        for (let key in this.filterSelections) {
            let selections = this.filterSelections[key];
            if (selections.length) {
                let param = [];
                for (let i = 0, value; i < selections.length; ++i) {
                    value = selections[i];
                    param.push(value);
                }
                params[key] = param;
            }
        }

        return params;
    }

    setPropertiesFromParams () {
        const params = this.$location.search();

        if (params.hasOwnProperty('page')) {
            let page = parseInt(params.page, 10);
            this.currentPage = page || 1;
        } else {
            this.currentPage = 1;
        }

        if (params.hasOwnProperty('q')) {
            let searchText = params.q;
            searchText = searchText.replace(/^\s+/, '');
            searchText = searchText.replace(/\s+$/, '');
            this.searchText = searchText || null;
        } else {
            this.searchText = null;
        }

        this.filterSelections = {};

        this.filterData.forEach((key, data) => {
            let selections = [];

            if (params.hasOwnProperty(key)) {
                let values = [];
                let paramValues = params[key];

                // Collect available option values
                data.options.forEach((option) => {
                    values.push(option.value);
                })

                if (typeof paramValues === 'string') {
                    paramValues = [paramValues];
                }

                paramValues.forEach((paramValue) => {
                    paramValue = parseInt(paramValue, 10);

                    // Add only known option values to selections
                    if (values.indexOf(paramValue) > -1) {
                        selections.push(paramValue);
                    }
                });
            }

            this.filterSelections[key] = selections;
        });
    }

    cancel () {
        if (this.results) {
            this.results.$cancelRequest();
        }
    }

    /**
     * Check to see if any filters are selected.
     *
     * By default, this includes the search text field as one of the
     * filters.
     */
    hasFilters () {
        if (this.searchText) {
            return true;
        }
        for (let k in this.filterSelections) {
            if (this.filterSelections[k].length) {
                return true;
            }
        }
        return false;
    }

    reset () {
        this.searchText = null;
        this.filterSelections = {};

        this.filterData.forEach((key) => {
            this.filterSelections[key] = [];
        });

        this.results = null;

        this.pageSize = 10;
        this.totalResults = null;
        this.numPages = null;
        this.currentPage = 1;
        this.previousPage = null;
        this.nextPage = null;
        this.range = null;

        this.inSearchMode = false;
        this.searchInProgress = false;

        this.cancel();
    }
}


export class ArticleFilterDataService {
    static $inject = ['$http'];

    private promise;
    private url = util.serviceUrl('articles/filter-data');

    constructor (private $http) {
        this.promise = this.requestFilterData();
    }

    then (action) {
        return this.promise.then((filters) => {
            return action(filters);
        });
    }

    forEach (action) {
        return this.promise.then((filters) => {
            let key, result, results = [];
            for (key in filters) {
                if (filters.hasOwnProperty(key)) {
                    result = action(key, filters[key]);
                    results.push(result);
                }
            }
            return results;
        });
    }

    private requestFilterData () {
        return this.$http.get(this.url)
            .then((response) => {
                return response.data.filters;
            }, () => {
                if (console) {
                    console.error('Failed to load article filter data');
                }
            });
    }
}
