import * as articleShowTemplate from './templates/show.html';


class ArticleController {
    static $inject = [
        '$location', '$route', '$routeParams', 'appConfig', 'articleFetchService',
        'articleSearchService', 'map', 'searchFactory', 'Point', '$mdSidenav'];

    user;
    article;
    formErrors;
    mainSiteName;
    inSearchMode: boolean = false;
    pointSearch;
    pointSearchResults;

    constructor (private $location, private $route, private $routeParams, private appConfig,
                 private fetchService, private searchService, private map,
                 private searchFactory, private Point, private $mdSidenav) {
        let params = {
            site_name: $routeParams.site_name,
            id: $routeParams.id
        };

        let inSearchMode = searchService.inSearchMode;

        this.user = appConfig.user;
        this.article = null;
        this.mainSiteName = appConfig.mainSiteName;
        this.inSearchMode = inSearchMode;

        this.pointSearch = searchFactory(Point, {
            successCallback: (results) => {
                this.pointSearchResults = results;
            },
            errorCallback: () => {
                this.pointSearchResults = null;
            }
        });

        map.clearSelectedFeatures();
        this.fetch(params);

        this.$mdSidenav('view').open();
    }


    fetch (params) {
        let article = this.fetchService.fetch(params);
        return article.$promise.then((article) => {
            const points = article.points;
            const selectFeature = this.map.selectFeature.bind(this.map);

            this.article = article;

            if (!this.inSearchMode) {
                this.searchService.searchText = article.title;
            }
            for (let point, i = 0, len = points.length; i < len ; ++i) {
                point = points[i];
                selectFeature(point.getFeature());
            }
            this.fitExtent();
        });
    }

    goToSearchResults () {
        const filters = this.searchService.getSearchFiltersFromProperties();
        if (filters) {
            this.$location.path('/search').search(filters);
            this.$route.reload();
        }
    }

    closeViewNav() {
        this.$mdSidenav('view').close();
    }

    showPointOnMap (point) {
        point.getFeature().then((feature) => {
            this.map.setCenterAtFeature(feature);
            this.map.zoomInToDefault();
        });
    }

    fitExtent () {
        const points = this.article.points;
        let coords = [];
        if (points.length) {
            for (let point, i = 0, len = points.length; i < len; ++i) {
                point = points[i];
                coords.push([point.long, point.lat]);
            }
            this.map.fitExtentOfCoordinates(coords, false, 1000);
        } else {
            this.map.zoomToFullExtent();
        }
    }

    addPoint (point) {
        if (this.article.addPoint(point)) {
            this.patchPoints();
        }
    }

    patchPoints () {
        this.article.$patchPoints(() => {
            this.$route.reload();
        }, (response) => {
            this.formErrors = response.data;
        });
    }
}


const ArticleComponent = {
    controller: ArticleController,
    template: articleShowTemplate
}


export default ArticleComponent;
